// converts the api endpoint format to a format to the PrimeReact tableTree format.
const formatCellData = (rowName, cellData) => {
    const returnData = {
        name: rowName
    }

    if (cellData) {
        console.log('cellData: ', cellData)
        for (const [ref, value] of Object.entries(cellData)) {
            console.log(`row: [rt7] ${ref}: `, value)
            returnData[ref] = formatCurrency(value.totalRevenue)
        }
    }
    
    return returnData
}

export const recursiveFormat = (subItems, keyPrepend = '') => {
    let key = 0
    const newRows = []
    for (const [i, value] of Object.entries(subItems)) {
      console.log(`row: [iu8] ${i}: `, value)
      const { rowData, cellData } = value
      console.log(rowData.name + ": rowData [ev6]: ", rowData)
      
      const newRow = {
        key: keyPrepend + key,
        // id: keyPrepend + key,
        data: formatCellData(rowData.name, cellData),
      }

      const { subItems } = value
      if (subItems) {
        console.group("item: " + rowData.name )
        newRow.children = recursiveFormat(subItems, keyPrepend + key + '-')
        console.groupEnd()
      }

      newRows.push(newRow)
      key++
    }

    return newRows
}

function formatCurrency(cents) {
    const dollars = cents / 100;  // Convert cents to dollars
    const parts = dollars.toFixed(2).toString().split("."); // Split into dollars and cents
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas to thousands
    console.log("parts: ", parts)
    return `$${parts[0]}` // return (without cents)
    
    // return `$${parts.join(".")}`;  // Combine back with decimal and dollar sign
}

// todo: delete this.
// export const formatData = (data) => {
//     console.log("data [ei8]: ", data)
//     const newData = []
//     let key = 0
//     for (const [i, value] of Object.entries(data.data)) {
//       console.log(`row: [er5] ${i}: `, value)
//       const { rowData } = value
//       const newRow = {
//         key: key,
//         // todo: update this:
//         data: {
//           name: rowData.name,
//           url: 'url',
//           size: '100kb',
//           type: 'Folder',
//         },
//       }
//       key = key + 1
//       const { subItems } = value
//       if (subItems) {
//         console.group("item: " + rowData.name )
//         newRow.children = recursiveFormat(subItems)
//         console.groupEnd()
//       }
//       newData.push(newRow)
//     }

//     return newData
// }