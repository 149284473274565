import React, { useEffect, useState } from "react"
import { TreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'

import axios from 'axios'
import { formatData, recursiveFormat } from "./formatData"

 export default () => {
  const [nodes, setNodes] = useState([]);
  const [columnsData, setColumnsData] = useState([]);

  const initialExpandedKeys = [4, 5, 6]

  // useEffect(() => {
  //   TestData.getTreeTableNodes().then((data) => setNodes(data));
  // }, [])

  const getColumnHeaders = (rawHeaders) => {
    const headers = []

    const columns = [
        {field: "name", header: "Name", expander: true }
    ];
    for (const [ref, caption] of Object.entries(rawHeaders)) {
      console.log(`header: [rt6] ${ref}: `, caption)
      
      const expander = (ref == 'name') ? true : false
      const newColumn = { field: ref, header: caption, expander: expander }
      columns.push(newColumn)
      
    }
    console.log("columns: ", columns)

    return columns
  }
  
  useEffect(() => {
    const response = axios.get('https://localhost:8000/reports/allMarketingChannels')
    response.then(function(result) {
      console.log('axios result (PASS) [ru7]: ', result.data)

      // convert to TableTree format and set the value
      const formattedTree = recursiveFormat(result.data.data)
      console.log("formattedTree #[bm4]: ", formattedTree)
      
      setColumnsData(getColumnHeaders(result.data.columnHeaders))
      setNodes(formattedTree)

      // setNodes(TTdata)
    }).catch ((e) => {
      console.log('axios result (FAIL / ERROR) [ru7]: ', e)
    });
  }, [])

  const sizeTemplate = (node) => {
    let size = node.data.size;
    let fontWeight = parseInt(size, 10) > 75 ? 'bold' : 'normal';

    return <span style={{ fontWeight: fontWeight }}>{size}</span>;
  }

  const rowClassName = (node) => {
    return { 'p-highlight': node.children && node.children.length === 3 };
  }

  const MyFunc = () => {
    console.log('asdf');
  }

  const customTemplate = (rowData) => {
    console.log('rowData: ', rowData);
    return (
      // <a href={rowData.url} target="_blank">
      <a onClick={() => MyFunc()}>{rowData.data.name}</a>
      // {rowData.name}
      // </a>
    )
  }

  return (
    <div className="card">
      <TreeTable
        expandedKeys={initialExpandedKeys}
        value={nodes}
        rowClassName={rowClassName}
        tableStyle={{ minWidth: '50rem' }}
      >
        {buildColumns(columnsData)}
        {/* <Column field="size" header="Size" body={sizeTemplate}></Column> */}
      </TreeTable>
    </div>
  )
}

const buildColumns = (columnsData) => {
  let columns = {}
  if (columnsData) { 
    console.log('columnsData #[gh7]: ', columnsData)
    columns = columnsData.map((col) => (
      <Column key={col.field} field={col.field} header={col.header} expander={col.expander} />
    ))
  }

  return columns
}