import React  from "react";

import Layout from "../../components/Layout";
import MCSalesTreeTable from "../../RobotGL/MCSalesTreeTable";

   // note: RDT = Recusive DataTable (referencing the complex data format)
const IndexPage = () => {

  return (
    <Layout>
      <h1>Market Channels sales report</h1>
      <MCSalesTreeTable />
      {/* <RecursiveDataTable tree={data} /> */}
    </Layout>
  )
}

// const RecursiveDataTable = ({ tree }) => {
//   if (!tree) { return (<></>)}

//   const cells = []
//   const { data } = tree
//   console.log('data [er6]: ', data)

//   for (const [key, value] of Object.entries(data)) {
//     const curCell = data[key]
//     console.log("curCell [ey7]: ", curCell)
//     cells.push(<MainRow curCell={curCell} />)
//     console.log(`Key: ${key}, Value: `, value);
//   }
//   console.log('tree: ', tree)

//   return (
//     <div>
//         RDT:
//         {cells}
//     </div>
//   )
// }

// const MainRow = ({ curCell }) => {
//   const { rowData } = curCell
//   console.log('curCell [my7]: ', curCell)
//   console.log('rowData [ms4]: ', rowData)
//   return (
//     <div>
//       <b>{rowData.name}</b>
//       <SubRows data={curCell.subItems} />
//     </div>
//   )
// }

// const SubRows = ({ data }) => {
//   console.log ('SubItems data [er5]: ', data)
//   if (!data) {
//     return (<></>)
//   }

//   const rows = []
//   for (const [key, value] of Object.entries(data)) {
//     const curRow = data[key]
//     console.log("curRow [ey9]: ", curRow)

//     rows.push(
//       <div>
//         {curRow.rowData.name}
//         <Cells data={curRow.cellData} />
//       </div>
//     )
//     console.log(`Key: ${key}, Value: `, value);
//   }

//   return (
//     <div>
//       {rows}
//     </div>
//   )
// }

// const Cells = ({ data }) => {
//   if (!data) {
//     return (
//       <>[]</>
//     )
//   }
//   const output1 = []
//   for (const [key, value] of Object.entries(data)) {
//     const curCell = data[key]
//     console.log("curCell [ee3]: ", curCell)

//     output1.push(
//       <span>
//         {centsToDollars(curCell.totalRevenue)}
//       </span>
//     )
//     console.log(`Key: ${key}, Value: `, value);
//   }
//   return (
//     <>{output1}</>
//   )
// }

// export const centsToDollars = (cents) => {
//   return  "$" + Math.floor(cents/100)
// }


export default IndexPage